@import '../../assets/scss';

main.faq-terms-about {
  overflow: hidden;

  .content {
    box-sizing: border-box;
    max-height: 100%;
    @include overflowScroll();
    padding: 5%;
    text-align: left;
    font-weight: $fontWeightNormal;
    margin: 0 (-$paddingXMainElementMobileDefault);

    a {
      color: $colorAccent;
    }

    h3 {
      font-weight: $fontWeightLight;
      font-size: $fontSizeSubHeading;
    }

    & > :first-child {
      margin-top: 0;
    }
  }

  &.faq-terms-about-terms {
    .content {
      background-color: $colorTermsBackground;
      color: $colorTermsText;

      h1, h2, h3, h4, h5, h6 {
        color: $colorTermsHeadline;
      }
    }
  }

  &.faq-terms-about-faq {
    .content {
      background-color: $colorFaqBackground;
      color: $colorFaqText;

      h1, h2, h3, h4, h5, h6 {
        color: $colorFaqHeadline;
      }
    }
  }

  &.faq-terms-about-about {
    .content {
      background-color: $colorAboutBackground;
      color: $colorAboutText;

      h1, h2, h3, h4, h5, h6 {
        color: $colorAboutHeadline;
      }
    }
  }

  &.faq-terms-about-mobile {
    @include mobile {
      .content {
        height: 100%;
      }
    }
  }
}
