@import '../../assets/scss';

$height: 90vh;
$width: 90vw;

.preview-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: $width;
  max-height: $height;
  background-color: $colorModalBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vh 1vh calc(2vh + 1.8rem) 1vh;
  border-radius: 5px;

  a.close {
    align-self: flex-end;
    padding: 10px 10px 0 0;

    img {
      height: $modalCloseBtnHeight;
      width:  $modalCloseBtnWidth;
    }
  }

  img.photo {
    max-width: calc(#{$width} - 5vw);
    max-height: calc(#{$height} - 5vh);
    padding: 1vh 5vw;
  }
}
