@import '../../assets/scss';

$slideGradient: to bottom, rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8), black;

.slide-mobile {
  @include mobile {
    height: 100%;
    width: 100%;
    //z-index: -1;
    position: relative;

    &.darkened {
      //background: rgba(0,0,0,0.8);
      .daily-navbar-container {
        background: none;
      }
      .slide-background {
        background-color: rgba(0,0,0,0.8);
        background-blend-mode: darken;
      }
    }

    .daily-navbar-container {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      z-index: 99;
      background: rgba(0,0,0,0.8);
      padding-top: 11px;
    }

    .slide-background {
      height: 100vh;
      width: 100vw;
      background-size: cover;
      background-position: center;
      position: absolute;
    }

    .slide-content {
      width: 100vw;
      position: absolute;
      bottom: 111px;
      top: $mobileHeaderHeightDefault;
      height: calc(100% - #{$mobileHeaderHeightDefault});
      @include overflowScroll();

      &.slide-content-preview-withlogo {
        top: $desktopHeaderHeight1200;
        height: calc(100% - #{$desktopHeaderHeight1200});
      }

      &.slide-content-summary {
        height: auto;
      }

      &.slide-content-maps {
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;

        .slide-content-centered {
          width: 100%;
        }
      }

      .slide-content-centered {
        height: 100%;

        &.slide-content-centered-summary {
          height: auto;
          padding-top: 70vh;

          &.preview-mode{
            padding-top: 60vh;
          }
        }
      }
    }

    &.slide-gallery {
      .slide-content-summary {
        bottom: 100px;

        .slide-content-centered-summary {
          padding-top: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
  }
}

.slide-desktop {
  height: 100%;
  width: 100%;
  position: relative;

  &.darkened {
    //background: rgba(0,0,0,0.8);
    .daily-navbar-container {
      background: none;
    }
    .slide-background {
      background-color: rgba(0,0,0,0.8);
      background-blend-mode: darken;
    }
  }

  .daily-navbar-container {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    z-index: 99;
    background: rgba(0,0,0,0.8);
    padding-top: 11px;

    #nav-item-summary-icon {
      height: 26px;
      width: 26px;
    }
  }

  .slide-background {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    position: absolute;
  }


  .slide-content {
    width: 100vw;
    position: absolute;
    bottom: 111px;
    top: $desktopHeaderHeightDefault;
    @include overflowScroll();

    &.slide-content-weather {
      //background: rgba(0,0,0,0.8);
    }

    .slide-content-centered {
      width: 100%;
      height: 100%;
      @include overflowScroll();

      &.slide-content-centered-summary {
        height: auto;
        padding-top: 46vh;
      }

      &.slide-content-centered-weather {
        height: 100%;
        @include overflowScroll();
      }
    }
  }

  &.slide-gallery {
    .slide-content-summary {
      bottom: 100px;

      .slide-content-centered-summary {
        padding-top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
    }
  }

  @include desktop1200 {
    .slide-content {
      top: $desktopHeaderHeight1200;
    }
  }
}