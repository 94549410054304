// Again, examples
$fontFamBody: 'Lato', sans-serif;
$fontFamHeader: 'Lato', sans-serif;

// Doubles as documentation for which font weights are needed
// Very useful when loading fonts as you can include only the ones used
// with some confidence
$fontWeightLight: 200;
$fontWeightNormal: 400;
$fontWeightBold: 600;

// Font sizes
// Note how you can use calc to make them relative to each other, meaning
// you can adjust the text size generally quite easily.

// This is for demonstration.
// However, consider specifying all font sizes in rem units.
// 1 rem = the font size of the html element, which you could set to fontSizeBody
// Hence, headers would then be able to be set to 3rem and 2rem respectively below
// This also doubles up with the benefit of adjusting if you set a smaller body
// font for mobile (everything adjusts)

$fontSizeBody: 1rem;
$fontSizeMobileNav: 0.75rem;
$fontSizeBodyLarge: calc( #{$fontSizeBody} * 1.3 ); // Deprecated
$fontSizeBodyMedium: calc( #{$fontSizeBody} * 1.2 ); // Deprecated
$fontSizeBodySmall: calc( #{$fontSizeBody} * 0.8 ); // Deprecated
$fontSizeBodyTiny: calc( #{$fontSizeBody} * 0.7 ); // Deprecated

$lineHeight: calc( #{$fontSizeBody} * 1.4 ); // Deprecated
$lineHeightExtraExtraExtraLarge: calc( #{$fontSizeBody} * 5 ); // Deprecated
$lineHeightExtraExtraLarge: calc( #{$fontSizeBody} * 3.5 ); // Deprecated
$lineHeightExtraLarge: calc( #{$fontSizeBody} * 2.5 ); // Deprecated
$lineHeightLarge: calc( #{$fontSizeBody} * 2 ); // Deprecated
$lineHeightSmall: calc( #{$fontSizeBody} * 1.1 ); // Deprecated
$lineHeightTiny: $fontSizeBody; // Deprecated

$fontSizeHeaderMax: calc( #{$fontSizeBody} * 4.7 ); // Deprecated
$fontSizeHeader0: calc( #{$fontSizeBody} * 4 ); // Deprecated
$fontSizeHeader1: calc( #{$fontSizeBody} * 3.7 ); // Deprecated
$fontSizeHeader2: calc( #{$fontSizeBody} * 2.3 ); // Deprecated
$fontSizeHeader3: calc( #{$fontSizeBody} * 1.7 ); // Deprecated
$fontSizeHeader4: calc( #{$fontSizeBody} * 1.4 ); // Deprecated
$fontSizeHeader5: calc( #{$fontSizeBody} * 1.2 ); // Deprecated
$fontSizeHeader6: calc( #{$fontSizeBody} * 0.9 ); // Deprecated

$fontSizeModalHeader: $fontSizeHeader4;
$fontWeightModalHeader: $fontWeightBold;

$fontSizeNavbar: $fontSizeBody;
$lineHeightNavbar: 1.2em;

$fontSizeSectionHeading: $fontSizeHeader3;
$lineHeightSectionHeading: $lineHeightExtraLarge;

$fontSizeParagraph: $fontSizeBody;
$lineHeightParagraph: $lineHeightSmall;

$fontSizeButton: $fontSizeParagraph;
$lineHeightButton: $lineHeightParagraph;

$fontSizeSubHeading: $fontSizeHeader4;

$fontSizeMobileHeaderIcon: 2.2rem;

$fontSizeListMobile: $fontSizeBodyMedium;
$fontWeightList: $fontWeightNormal;


