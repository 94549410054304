@import "../constants/colors";
@import "./screenTypes";
@import "../constants/sizes";

@mixin overflowScroll() {
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }

}

@mixin ulRemoveDefaults() {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

@mixin headerHamburgerPositioning() {
  position: fixed;
  left: 93vw;
  top: 2vh;

  @include desktop1200 {
    left: 90vw;
  }

  @include mobile {
    left: 1vw;
    top: 1vh;
  }
}

@mixin headerBackButtonPositioning() {
  position: fixed;
  right: 93vw;
  top: 2vh;

  @include desktop1200 {
    right: 90vw;
  }

  @include mobile {
    left: 1vw;
    top: 1vh;
  }
}

@mixin tileGrid($type: summary) {
  display: grid;
  grid-gap: 10px;
  grid-auto-rows: max-content;
  @include overflowScroll();

  @if $type == vouchers {
    grid-template-columns: repeat(3, 1fr);
  } @else {
    grid-template-columns: repeat(2, 1fr);
  }

  .tile-grid-tile {
    height: 0;
    @if $type == gallery {
      padding-bottom: 100%;
    } @else if $type == vouchers {
      padding-bottom: 67%;
    } @else if $type == summary {
      padding-bottom: 32%;
    }
  }

  @include mobile {
    @if $type == summary {
      grid-template-columns: repeat(2, 1fr);

      @include mobile768 {
        grid-template-columns: 1fr;
      }
    } @else {
      @include mobile768 {
        grid-template-columns: repeat(2, 1fr);

        @include mobile360 {
          grid-template-columns: 1fr;
        }
      }
    }



    //@if $square {
    //  @include mobile768 {
    //    grid-template-columns: repeat(2, 1fr);
    //
    //    @include mobile360 {
    //      grid-template-columns: 1fr;
    //    }
    //  }
    //} @else {
    //  grid-template-columns: repeat(2, 1fr);
    //
    //  @include mobile768 {
    //    grid-template-columns: 1fr;
    //  }
    //}
  }
}

@mixin removeButtonStyling() {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin list() {
  height: $listHeight;
  line-height: $listLineHeight;
  font-size: $fontSizeBody;
  font-weight: $fontWeightNormal;

  @include mobile {
    height: $listHeightMobile;
    line-height: $listLineHeightMobile;
  }
}
