@import '../../../assets/scss';

.desktop-header {
  //max-height: 30vh;
  height: $desktopHeaderHeightDefault;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close-brief {
    position: fixed;
    left: 10vw;
    top: 2vh;
    filter: $filterButtonShadow;

    img {
      height: 23px;
      width: 23px;
    }
  }

  .hamburger-btn-container {
    @include headerHamburgerPositioning();
  }

  .logo {
    max-height: $heightDesktopLogoDefault;
    margin: $marginYDesktopLogo 0;
    z-index: 1;
    max-width: 25vw;

    &.noLogoDisplay {
      display: none;
    }
  }

  @include desktop1200 {
    height: $desktopHeaderHeight1200;

    .logo {
      max-height: $heightDesktopLogo1200;
    }
  }

  &.header-barebones {
    height: $desktopHeaderHeightDefault;

    .back-button {
      @include headerBackButtonPositioning();
      .back-button-icon {
        font-size: calc(#{$fontSizeHeader3} + 0.5rem);
        //color: white;
      }

      &.btn-color-white {
        color: $colorWhite;
      }

      &.btn-color-login-nav {
        color: $colorLoginNavigation;
      }

      &.btn-color-accent {
        color: $colorAccent;
      }


    }
  }

  // NOTE: desktop header is still used for mobile home screen
  @include mobile {
    height: auto;

    .logo {
      max-height: $heightMobileLogoDefault;
      margin-top: 7vh;
      max-width: 50vw;
    }
  }

  @include mobile768 {
    .logo {
      max-height: $heightMobileLogo768;
    }
  }
}
