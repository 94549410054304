@import '../../assets/scss';

.weather-item {
  font-size: $fontSizeBody;
  //border-bottom: 1px solid $colorListDivider;
  border-bottom: $listSeparatorBorder;
  padding: $listPaddingDesktop;

  .weather-temp {
    flex-shrink: 0;
  }

  .top {
    display: flex;
    justify-content: space-between;
    //height: $listHeight;
    //line-height: $listLineHeight;
    @include list();

    &.pointer{
      cursor: pointer;
    }

    .name {
      text-align: left;
    }

    .main-weather-info {
      display: flex;
      align-items: center;
      gap: 0.5em;
      justify-content: flex-end;

      .chevron {
        transition: transform .3s linear;
      }
    }
  }

  .bottom {
    //font-size: $fontSizeBody;
    height: 0;
    overflow: hidden;
    transition: height .3s linear;

    li.weather-day {
      display: flex;
      justify-content: space-between;
      padding: 2rem 0;
      //border-bottom: 1px solid $colorListDivider;
      border-bottom: $listSeparatorBorder;
      //margin: 1rem 0;

      &:last-of-type {
        border: none;
      }

      .date-info {}

      ul.weather-icons {
        display: flex;
        gap: 2rem;

        li {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @include desktop {
    &.active {
      .top {
        .name {
          color: $colorAccent;
        }

        .main-weather-info {
          .weather-temp,
          .weather-icon {
            display: none;
          }

          .chevron {
            transform: rotate(180deg);
          }
        }
      }
      .bottom {
        height: auto;
      }
    }
  }


  @include mobile {
    padding: 0;

    .top {
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        //font-size: $fontSizeHeader5;
      }
      .main-weather-info {
        .chevron {
          transition: none;
          transform: rotate(270deg);
        }
      }
    }

    .bottom {
      transition: none;
    }

    &.active {
      border-bottom: none;

      .top {
        height: auto;
        justify-content: center;

        .name {
          font-size: $fontSizeSubHeading;
        }

        .main-weather-info {
          display: none;
        }
      }

      .bottom {
        height: auto;

        li.weather-day {
          font-size: inherit;

          &:first-of-type {
            border-top: 1px solid $colorGrey;
          }

          ul.weather-icons {
            gap: 1rem;
          }

          &.inactive {
            align-items: center;
            padding: 0;
            //height: $listHeight;
            //line-height: $listLineHeight;
            @include list();

            ul.weather-icons {
              li {
                &.show-in-preview {
                  flex-direction: row;
                  align-items: center;
                  gap: 15px;

                  .weather-time {
                    display: none;
                  }
                }

                &.hide-in-preview {
                  display: none;
                }
              }
            }
          }

          &.active {
            padding: 1rem 0;
            flex-wrap: wrap;

            .date-info {
              width: 100%;
              margin-bottom: 15px;
            }

            ul.weather-icons {
              width: 100%;
              justify-content: flex-end;

              li {
                &.hide-in-preview {
                  display: flex;
                }
              }
            }
          }

          .date-info {
            text-align: left;
          }
        }
      }
    }

    &.hidden {
      display: none;
    }

    @include mobile480 {
      .weather-icon {
        height: 40px;
      }
    }
  }

}
