@import '../../assets/scss';

.post-uploader {
  background-color: $colorPostTile;
  width: calc(#{$postsWidthDefault} - (2 * #{$postTilePaddingDefault}));
  padding: $postTilePaddingDefault;
  margin: 1rem auto 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: relative;

  .MuiCircularProgress-root {
    position: absolute;
    top: calc(50% - #{$postTilePaddingDefault});
    left: calc(50% - #{$postTilePaddingDefault});
  }

  .desktop-content {
    display: flex;
    justify-content: center;

    a.close {
      position: absolute;
      top: 15px;
      right: 15px;
      align-self: flex-end;

      img {
        height: $modalCloseBtnHeight;
        width:  $modalCloseBtnWidth;
      }
    }

    .header {
      color: $colorModalHeadline;
      font-size: $fontSizeModalHeader;
      font-weight: $fontWeightModalHeader;
      margin-bottom: calc(#{$modalPadding} / 2);
    }
  }

  img.preview {
    max-height: 40vh;
    max-width: 100%;
    object-fit: contain;

    &.hidden {
      display: none;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .button {

    &:active {
      opacity: 50%;
    }
    &:focus {
      background-color: $colorWhite;
      color: $colorAccent;
      outline: 1px solid $colorAccent;
    }
  }

  @include mobile {
    width: calc(#{$postsWidthMobile} - (2 * #{$postTilePaddingMobile}));
    padding: $postTilePaddingMobile;
  }
}
