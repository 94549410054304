@import '../../assets/scss';

.weather {
  display: grid;
  grid-template-rows: 1fr 0fr;
  overflow: hidden;

  ul {
    @include ulRemoveDefaults();
  }

  ul.weather-items-container {
    @include overflowScroll();
  }

  .toggle-units-container {
    justify-self: flex-end;
  }

  @include mobile {
    grid-template-rows: 95fr 5fr;

    ul.weather-items-container {
      overflow: hidden;
      //padding-inline-start: 1vw;
      //padding-inline-end: 1vw;

      [data-simplebar] {
        max-height: calc(95%) !important;
        padding-right: 20px;
      }
    }
  }
}