@import '../../assets/scss';

.daily-flights {
  height: 100%;

  &.flights-desktop {
    @include desktop {
      //width: calc(98vw - 80px - 20px); // Keep within arrows
      //max-width: calc(1400px - 80px - 20px); // check this + responsive
      width: $mainDesktopWidthDefault;
      max-width: $mainDesktopMaxWidthDefault;
      margin: auto;

      @include desktop1200 {
        width: $mainDesktopWidth1200;
      }
    }
  }

  &.flights-mobile {
    @include mobile {
      padding: 0 1vw;
    }
  }
}