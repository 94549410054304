@import '../../assets/scss';

.post-slide {
  width: 100vw;
  height: 100%;
  font-weight: $fontWeightNormal;
  display: flex;
  justify-content: center;


  .post-slide-content {
    position: absolute;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;

    .date-info {
      margin-bottom: 1rem;

      .day {
        font-size: $fontSizeSubHeading;
        margin-bottom: 0.25rem;
      }
    }

    .content {
      @include overflowScroll();
      height: auto;
      padding: 0;
    }

    ul {
      @include ulRemoveDefaults();
      width: $postsWidthDefault;
      margin: 0 auto !important;

      li.post-item {
        background-color: $colorPostTile;
        padding: $postTilePaddingDefault;
        margin: $postTileMarginDefault;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .top {
          display: flex;
          justify-content: space-between;
          font-size: $fontSizeBodySmall;

          .post-info {
            color: $colorGreyLight;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .options {
            color: $colorAccent;
            cursor: pointer;

            .circle {
              height: 0.25rem;
              width: 0.25rem;
              border: 1.5px solid $colorAccent;
              border-radius: 50%;
              display: inline-block;
              margin: 0 0.05rem;
            }
          }
        }

        .text-content {
          text-align: left;
          color: $colorBlack;
        }

        img {
          max-width: 100%;
          height: 45vh;
          object-fit: contain;
          margin: 0 auto;
          cursor: pointer;
        }
      }
    }
    .placeholder-image {
      width: calc(#{$postsWidthDefault} - (2 * #{$postTilePaddingDefault}));
      padding: $postTilePaddingDefault;
      margin: 0 auto;
      display: flex;
      height: 52%;
      position: relative;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 150%;
      }

      @include mobile {
        width: calc(#{$postsWidthMobile} - (2 * #{$postTilePaddingMobile}));
        padding: $postTilePaddingMobile;
        height: 70%;
      }
    }
  }

  &.post-slide-desktop {
    @include desktop {
      .post-slide-content {
        width: $mainDesktopWidthDefault;
        max-width: $mainDesktopMaxWidthDefault;
        height: 100%;
      }

      @include desktop1200 {
        .post-slide-content {
          max-width: 70vw;
        }
      }
    }

    .posts-header {
      width: $postsWidthDefault;
      margin: 0 auto !important;
      display: flex;
      justify-content: center;
      height: fit-content;

      .photobook-icon-container {
        height: 1.5rem;
        width: 1.5rem;
        align-self: center;
        margin-bottom: 1rem;
      }

      &.photobook-link {
        text-align: left;
        justify-content: space-between;
      }
    }
  }

  .hide {
    display: none;
  }

  &.post-slide-mobile {
    @include mobile {
      .post-slide-content {
        top: $mobileHeaderHeightDefault;
        height: calc(100% - #{$mobileHeaderHeightDefault});
        width: $mainMobileWidthDefault;
        padding-top: 0;

        .date-info {
          margin-bottom: 2rem;
        }

        ul {
          width: $postsWidthMobile;

          li.post-item {
            padding: $postTilePaddingMobile;

            img {
              max-height: 72vh;
            }
          }
        }
      }

      &.mobile-view-generic {
        background-color: $colorWhite;

        .post-slide-content {
          width: 100vw;
          padding: 0;

          .mobile-preview {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
              max-height: 100%;
              max-width: 100%;
              object-fit: contain;
            }
          }
        }
      }

      @include mobile768 {
        .post-slide-content {
          width: $mainMobileWidth768;

          .date-info {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}


