@import '../../assets/scss';

$backgroundShadow: rgba(0,0,0,0.8);

.detail {
  height: 100%;
  width: 100%;
  position: relative;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    z-index: 0;
  }

  .inner-content {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .title {
      font-size: $fontSizeSectionHeading;
      margin-bottom: 5px;
      padding: 5px 1vw 0 1vw;
      height: 5%;
      z-index: 1;
    }

    video {
      background: black;
      height: 50%;
      z-index: 1;
    }

    .description-container {
      text-align: left;
      @include overflowScroll();
      flex-grow: 1;
      z-index: 1;
      display: flex;
      flex-direction: column;

      .empty {
        height: 0;
      }

      .description {
        box-sizing: border-box;
        //min-height: 100%;
        height: fit-content;
        padding: 5% 1vw 50pt 1vw;
        @include linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $backgroundShadow 50pt);
      }
    }

    ul.buttons {
      @include ulRemoveDefaults();
      display: flex;
      justify-content: space-evenly;
      background: $backgroundShadow;
      padding: 5% 0;
      box-sizing: border-box;
      z-index: 1;

      li {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          color: $colorWhite;
          text-decoration: none;
          gap: 5pt;

          img {
            height: 23px;
            width: 23px;
          }
        }
      }

      li {
        button {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          color: $colorWhite;
          text-decoration: none;
          gap: 5pt;
          background: none;
          border: none;
          cursor: pointer;
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;

          img {
            height: 23px;
            width: 23px;
          }
        }
      }

    }
  }

  &:not(.has-background-image) {
    @include linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 100%);

    .inner-content {
      .description-container {
        .description {
          background: none;
        }
      }

      ul.buttons {
        background: none;
      }
    }
  }

  &:not(.has-background-image),
  &.has-video {
    .inner-content {
      .description-container {
        .description {
          height: 100%;
        }
      }
    }
  }

  &.has-background-image {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    &:not(.has-video) {
      .inner-content {
        .description-container {
          .empty {
            min-height: 50%;
            height: 100%;
          }
        }
      }

      &:not(.has-description) {
        .inner-content {
          .description-container {
            .description {
              @include linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $backgroundShadow 100%);
            }
          }
        }
      }
    }
  }

  &:not(.detail-directories) {
    .inner-content {
      .description-container {
        text-align: center;
      }
    }
  }

  @include desktop {
    .shadow {
      background: rgba(0,0,0,0.2);
    }

    .inner-content {
      .description-container {
        .description {
          padding-top: 2%;
          padding-bottom: 50pt;
        }
      }

      ul.buttons {
        padding-bottom: 2%;
        padding-top: 2%;
      }
    }

    &.has-video {
      .shadow {
        background: $backgroundShadow;
      }

      .inner-content {
        .description-container {
          .description {
            background: none;
          }
        }

        ul.buttons {
          background: none;
          @include linear-gradient(to bottom, rgba(0,0,0,0) 0%, black 75%);
        }
      }
    }
  }

  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .shadow {
      position: fixed;
    }

    .inner-content {
      height: calc(100% - #{$mobileHeaderHeightDefault});
      margin-top: calc(#{$mobileHeaderHeightDefault});

      video {
        height: 35vh;
      }

      .description-container {
        .description {
          $paddingX: calc(#{$paddingXContainerMobileDefault} + #{$paddingXMainElementMobileDefault});
          padding: 5% $paddingX 50pt $paddingX;
        }
      }

      @include mobile768 {
        .description-container {
          .description {
            $paddingX: calc(((100vw - #{$mainMobileWidth768}) / 2) + #{$paddingXMainElementMobileDefault});
            padding: 5% $paddingX 50pt $paddingX;
          }
        }
      }
    }

    &.has-background-image {
      &:not(.has-video) {
        &:not(.has-description) {
          .inner-content {
            .description-container {
              .description {
                @include linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $backgroundShadow 100%);
              }
            }
          }
        }
      }
    }
  }
}