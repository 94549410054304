@import "./../../assets/scss";

.container-view-all {
  main.view-all {
    @include tileGrid();
    padding: $paddingYContainerMobilePreview $paddingXContainerMobileDefault;

    .view-all-tile {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      background-color: $colorWhite;
      padding: calc( #{$paddingTileGrid} * 2 );
      gap: calc( #{$paddingTileGrid} * 2 );

      height: auto;

      &.active {
        background-color: $colorAccent;

        .text-content {
          color: $colorWhite;
        }

        .buttons {
          button {
            background-color: $colorWhite;
            color: $colorBlack;

            &:active {
              opacity: 50%;
            }
            &:focus {
              color: $colorAccent;
            }
          }
        }
      }
      .button {

        &:active {
          opacity: 50%;
        }
        &:focus {
          background-color: $colorWhite;
          color: $colorAccent;
          outline: 1px solid $colorAccent;
        }
      }

      .text-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $colorBlack;

        .date {
          font-size: $fontSizeSubHeading;
          //background-color: red;
        }

        .title {
          //background-color: blue;
          flex-basis: 50%;
          display: flex;
          align-items: center;
          @include clamp_lines(2, 1.2rem);
          overflow: hidden;

        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }

  &.mobile-view-generic {
    background-color: $colorDefaultBackground;
  }
}

.center-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
