@import '../../../assets/scss';

footer {
  width: 100%;
  //position: fixed;
  //bottom: 0;
  z-index: 1;

  @include mobile {
    margin-bottom: 10px;
    padding-right: 20px;
    opacity: 0.8;
    box-sizing: border-box;
    zoom: 0.6;
  }

  .powered-by {
    text-align: right;
    margin-bottom: 1vh;
    margin-right: 6vw;
    font-size: $fontSizeBodySmall;

    img {
      height: $fontSizeBodySmall;
      z-index: 1;
    }
  }

  @media screen and (max-width: 1200px) {
    .powered-by {
      margin-right: 2vw;
    }
  }

  a {
    text-decoration:none;
    color: white !important;
  }
}
