@import '../../assets/scss';

main.directory {
  &.directory-desktop {
    box-sizing: border-box;
    padding-top: 1vh;
    position: relative;
    word-break: break-word;

    .back-button {
      color: $colorWhite;
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 1vh;
      padding-left: 0;
      z-index: 2;

      .back-button-icon {
        font-size: calc(#{$fontSizeHeader3} + 0.5rem);
      }
    }
  }
}
