// example mixin -- clamp number of lines displayed to a set number.
// use example: h1 { @include clamp_lines(3); }

@mixin clamp_lines($lines, $lineHeight: 1.6em) {
    display: block;
    line-height: $lineHeight;
    max-height: calc( #{$lineHeight} * #{$lines} );
    text-overflow: ellipsis;
    text-overflow-ellipsis: ellipsis;
    //overflow: scroll;

    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}