@import '../../assets/scss';

$colorBackground: $colorWhite;
$colorHeader: $colorGreyVeryLight;
$colorGrayed: $colorGreyLight;
$colorText: $colorBlack;

$bullet-size: 36px;
$padding: 12px;

// TODO if all rows are display grid, externalise

.flight-details {
  $parent: &;
  font-weight: $fontWeightNormal;
  color: $colorText;
  margin: 0;

  &.active {
    .header {
      color: $colorBackground;
      background-color: $colorAccent;

      .row {
        &.middle {
          .icon {
            .icon-styling {
              color: white;
              background-color: $colorAccent;
            }

            &::before,
            &::after {
              border-color: $colorBackground;
            }
          }
        }
      }
    }
  }

  .row {
    margin: 0 5px;
    display: grid;

    & > div {
      padding: 0;
    }
  }

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .header {
    padding: $padding;
    background-color: $colorHeader;

    .row {
      &.top {
        grid-template-columns: 1fr 1fr;
      }

      &.middle,
      &.bottom {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &.middle {
        .icon {
          position: relative;
          margin-top: calc(0.25em);
          margin-bottom: 0;

          &::before, &::after {
            content: '';
            border-bottom: 2px solid $colorText;
            width: 100%;
            position: absolute;
            top: 40%;
            left: 0;
            transform: translateY(-6px);
            z-index: 0;
          }

          .icon-styling {
            transform: rotate(90deg);
            position: relative;
            z-index: 1;
            box-sizing: content-box;
            padding: 4px 10px;
            background-color: $colorHeader;
            transition: none;
            color: $colorText;
            border-radius: 10px;
            top: -5px;
            font-size: 32px;
          }
        }
      }

      &.bottom {
        .flight-duration {
          width: 130px;
          color: $colorText;
          display: inline-block;
          background-color: $colorBackground;
          border-radius: 1rem;
          padding: 0 16px;
        }
      }
    }
  }

  .body {
    padding: $padding;
    background-color: $colorBackground;

    &.flight-times {
      .row {
        grid-template-columns: 1fr 1fr;

        &.day-difference {
          grid-template-columns: 1fr 1fr;
          justify-items: center;

          span {
            background-color: $colorHeader;
            border-radius: 1rem;
            padding: 0 8px;
            display: inline-block;
            margin-right: calc(1rem + 26px);

            @include mobile {
              margin-right: 0;
            }
          }
        }

        &.default-times {
          grid-template-columns: 1fr 1fr;

          .center {
            .text-cage {
              border: 1px solid $colorGrayed;
              padding: 4px;
            }
          }
        }

        &.alerts {
          grid-template-columns: 1fr 1fr;

          .big-text {
            font-weight: $fontWeightNormal;
            font-size: $fontSizeHeader3;

            @include mobile {
              font-size: $fontSizeHeader4;
            }

            & sup {
              //top: -1rem;
              font-size: $fontSizeHeader6;
            }
          }
        }

        &.cancelled {
          grid-template-columns: 1fr;

          .alert {
            p {
              margin: 0;
            }
          }
        }
      }

      .alert {
        color: $colorAlert;
      }
    }

    &.flight-airport-info {
      .row {
        grid-template-columns: 1fr 1fr 1fr;

        .grayed {
          font-size: $fontSizeBodySmall;
          color: $colorGrayed;
        }

        &.flight-num-airline {
          grid-template-columns: 1fr 2fr;
        }

        &.last-updated-at {
          grid-template-columns: 1fr;
        }
      }
    }

    .row {
      margin-bottom: 10px;
    }
  }

  .separator {
    padding: 0 calc(#{$bullet-size}/2);
    position: relative;
    background-origin: content-box;
    overflow: hidden;
    background-clip: content-box;
    height: $bullet-size + 2px;
    background-color: $colorBackground;

    .line {
      position: absolute;
      top: 50%;
      left: calc(#{$bullet-size}/2);
      right: calc(#{$bullet-size}/2);
      border-bottom: 2px dashed $colorGrayed;
      z-index: 2;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      background-color: transparent;
      width: $bullet-size;
      height: $bullet-size;
      border-radius: 50%;
      top: 50%;
      transform: translateY(calc(#{$bullet-size}/-2));
      box-shadow: 0 0 0 $bullet-size*2 $colorBackground;
      z-index: 1;
      margin: 1px 0;
    }

    &::before {
      left: calc(-1 * (#{$bullet-size}/2));
    }

    &::after {
      right: calc(-1 * (#{$bullet-size}/2));
    }
  }

  .map {
    background: $colorBackground;
    width: 100%;
    padding-top: 100%;
    position: relative;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .huge-text {
    font-weight: $fontWeightBold;
    font-size: $fontSizeHeader2;

    @include mobile {
      font-size: $fontSizeHeader3;
    }
  }
}
