@import '../../assets/scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 20pt;

  a {
    color: $colorWhite;
    text-decoration: none;
  }

  .title {
    font-size: $fontSizeSectionHeading;
  }

  .description {
    text-align: left;
  }

  .list-items-container {
    .list-item {
      text-align: left;
      position: relative;

      &.list-item-placeholder {
        pointer-events: none;
        display: none;
      }

      .list-item-gradient {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1vh;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;

        .title {
          white-space: nowrap;
        }

        & > * {
          font-size: $fontSizeBody;
          text-overflow: ellipsis;
        }
      }

      &:not(.list-item-directories) {
        background-color: black;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .list-item-gradient {
          @include linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0));
          padding: $paddingTileGrid;

          .title {
            font-weight: $fontWeightBold;
            height: 20%;
            overflow-x: clip;
          }

          .description {
            height: 35%;
            overflow: hidden;
          }
        }
      }
    }
  }

  &.list-directories {
    .list-items-container {
      .list-item {
        @include list();
        border-bottom: $listSeparatorBorder;
        display: flex;

        .list-item-gradient {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 1vw;


          //.title {
          //  text-overflow: ellipsis;
          //  overflow: hidden;
          //  white-space: nowrap;
          //}

          img {
            transform: rotateZ(-90deg);
          }
        }
      }
    }
  }

  &.list-vouchers {
    .list-items-container {
      @include tileGrid(vouchers);
      .description {
        @include clamp_lines(3, 1.1em);
      }
    }
  }

  &.list-dailyActivities {
    width: 100vw;
    @include overflowScroll();

    .date-info {
      display: flex;
      flex-direction: column;

      .day-name {
        font-size: $fontSizeSubHeading;
        margin-bottom: 0.25rem;
      }
    }
    .list-items-container {
      @include tileGrid(summary);

      .description {
        @include clamp_lines(2, 1.1em)
      }
    }

    @include desktop {
      & > * {
        width: $mainDesktopWidthDefault;
        max-width: $mainDesktopMaxWidthDefault;
        margin: 0 auto;

        @include desktop1200 {
          width: $mainDesktopWidth1200;
        }
      }
    }
  }

  @include mobile {
    .list-items-container {
      .list-item {
        padding: 0;
      }
    }

    &.list-dailyActivities {
      $paddingX: calc(#{$paddingXContainerMobileDefault} + #{$paddingXMainElementMobileDefault});
      box-sizing: border-box;
      padding: $mobileHeaderHeightDefault $paddingX 0 $paddingX;
    }
  }
}