@import '../../../assets/scss';

.mobile-header {
  @include mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $fontSizeBody;
    z-index: 1;

    .button {
      &.btn-color-white {
        color: $colorWhite;
      }

      &.btn-color-login-nav {
        color: $colorLoginNavigation;
      }

      &.btn-color-accent {
        color: $colorAccent;
      }

      &.color-black {
        color: $colorBlack;
      }
    }

    .back-button {
      .back-button-icon {
        font-size: $fontSizeMobileHeaderIcon;
      }
    }

    .screenname {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 75%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.color-black {
        color: $colorBlack;
      }
    }

    .right-button {
      svg {
        font-size: $fontSizeMobileHeaderIcon;
      }
    }

    &.mobile-view-generic {
      color: $colorBlack;
      background-color: $colorWhite;
      -webkit-box-shadow: 0 4px 6px -6px black;
      -moz-box-shadow: 0 4px 6px -6px black;
      box-shadow: 0 4px 6px -6px black;
    }
  }

  .photobook-icon-container {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
}
