@import '../../../assets/scss';

$padding: 5px;

.text-input {
  padding: calc(#{$padding} * 1.5) $padding;
  outline: none;
  border: 2px solid $colorAccent;
  border-radius: 5px;
  width: calc(100% - #{$padding * 2});
  cursor: pointer;

  &.translucent-background {
    background-color: rgba(0,0,0,0.5);
    border-color: $colorWhite;
    color: $colorWhite;
  }

  &.input-field-square-corners {
    border-radius: 0;
  }

  &::placeholder {
    color: $colorGreyLight;
  }

  &:focus {
    //border-bottom: 2px solid $colorAccent;
    caret-color: $colorAccent;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  @include mobile {
    border-width: 1px;
  }
}