@import '../../assets/scss';

.flights {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  overflow: hidden;

  .flight-list-items-container {
    @include ulRemoveDefaults();
    @include overflowScroll();
  }

  .flight-details-container {
    //background-color: blue;
    @include overflowScroll();
  }

  &.flights-mobile {
    @include mobile {
      grid-template-columns: 1fr 0;
      grid-column-gap: 0;

      .flight-details-container {
        display: none;
      }

      &.mobile-details-mode {
        .flight-list-items-container {
          display: none;
        }

        .flight-details-container {
          display: block;
        }
      }
    }
  }

}