@import '../../assets/scss';

$bullet-size: 36px;
$padding: 12px;

.flight-list-item {
  $parent: &;
  background-color: $colorWhite;
  color: $colorBlack;
  display: block;
  font-weight: $fontWeightNormal;
  padding: $padding;
  z-index: 1;
  margin-bottom: 1rem;

  &.active {
    color: $colorWhite;
    background-color: $colorAccent;

    .time-estimated {
      color: $colorBlack;
    }

    .center {
      .flight-number {
        background-color: $colorWhite;
        color: $colorBlack;
        font-weight: $fontWeightNormal;
      }

      .icon {
        .icon-styling {
          color: $colorWhite;
        }
      }
    }
  }

  a {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-decoration: none;
    margin: 0 5px;

    &:link,
    &:visited,
    &:hover,
    &:active,  {
      text-decoration: none;
      color: $colorBlack;
    }

    & > * {
      padding: 0;
    }

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }

    .left,
    .right,
    .center {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .date {
      word-spacing: 5px;
      font-weight: 900;
      // font-size: 16px;
      font-size: $fontSizeBodyMedium;
    }

    .time-estimated {
      color: $colorBlack;
    }

    .time-cancelled {
      color: $colorAlert;
    }

    .center {
      gap: 0;
      align-items: center;
      justify-content: space-between;

      .flight-number {
        color: $colorWhite;
        display: inline-block;
        width: 130px;
        background-color: $colorAccent;
        border-radius: 1em;
        padding: 4px 0;
      }

      .icon {
        margin-bottom: 8px;

        .icon-styling {
          transform: rotate(90deg);
          color: $colorBlack;
          font-size: 32px;
        }
      }
    }
  }

  @include desktop {
    &.selected {
      padding: 0 calc(#{$bullet-size}/2);
      position: relative;
      background-origin: content-box;
      overflow: hidden;
      background-clip: content-box;

      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: transparent;
        width: $bullet-size;
        height: $bullet-size;
        border-radius: 50%;
        top: 50%;
        transform: translateY(calc(#{$bullet-size}/-2));
        box-shadow: 0 0 0 50px $colorWhite;
        z-index: -1;
        margin: 1px 0;
      }

      &.active::before,
      &.active::after {
        box-shadow: 0 0 0 $bullet-size*2 $colorAccent;
      }

      &::before {
        left: calc(-1 * (#{$bullet-size}/2));
      }

      &::after {
        right: calc(-1 * (#{$bullet-size}/2));
      }

      a {
        padding: $padding 0;

        .left {
          //padding-left: $padding;
          padding-left: 0;
        }

        .right {
          //padding-right: $padding;
          padding-right: 0;
        }
      }
    }
  }

  @include mobile {
    @include mobile768 {
      a {
        .center {
          .flight-number {
            width: 100px;
          }
        }
      }
    }
  }

  //pointer-events: none;
}