@import '../../../assets/scss';

$scrollBtnMarginTop: 20px;

.carousel-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  & > .scroll-btn {
    position: absolute;
    top: calc(50% - #{$scrollBtnMarginTop} - (#{$chevronLargeHeightDefault}/2));
    //width: 100px;
    z-index: 1;
    margin: $scrollBtnMarginTop 10px;
    width: auto;

    &.left {
      left: 1vw;
    }

    &.right {
      right: 1vw;
    }
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    transition: margin 0.5s ease-in-out;
    height: 100%;
  }

  @include desktop {
    @include desktop1200 {
      & > .scroll-btn {
        $indent: calc(49vw - #{$scrollBtnWidth} - (min(#{$mainDesktopMaxWidthDefault}, #{$mainDesktopWidth1200})/2));

        &.left {
          left: $indent;
        }

        &.right {
          right: $indent;
        }
      }
    }
  }

  @include mobile {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    & > .scroll-btn {
      //$indent: calc(52vw - 700px);
      top: calc(50% - #{$scrollBtnMarginTop} - (#{$chevronLargeHeightMobile}/2));

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }

    &.mobile-scroll-buttons-top > .scroll-btn {
      top: $mobileHeaderHeightDefault;
      margin-top: 0;
      margin-bottom: 0;
    }

    .carousel {
      display: grid;
      grid-template-columns: repeat(1000, 100vw);
      z-index: -1;
    }
  }

    @include mobile768 {
      & > .scroll-btn {
        top: calc(50% - #{$scrollBtnMarginTop} - (#{$chevronLargeHeightMobile768}/2));
      }

      @include mobile540 {
        & > .scroll-btn {
          top: calc(50% - #{$scrollBtnMarginTop} - (#{$chevronLargeHeightMobile540}/2));
        }
      }
    }


}