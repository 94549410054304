@import '../../assets/scss';

main.brief {
  &.brief-desktop {
    @include desktop {
      .brief-header {
        top: 0;
        left: 0;
        width: 100vw;
        position: absolute;
        z-index: 1;
      }
    }
  }

  &.brief-mobile {
    @include mobile {
      .brief-header {
        display: none;
      }
    }
  }

  &.brief-mobile {
    @include mobile {
      .brief-header-displaylogo {
                top: 0;
                left: 0;
                width: 100vw;
                position: absolute;
                z-index: 1;
      }
    }
  }

  .gallery-view-all-icon-container {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 100px;
    z-index: 99;
    background: rgba(0,0,0,0.8);
    //padding-top: 11px;

    svg.MuiSvgIcon-root {
      color: $colorWhite;
    }
  }
}
