@import './assets/scss';

.app {
  text-align: center;
  height: 100vh;
  z-index: 100;

  #gradient {
    @include linear-gradient($appGradient);
    height: 100%;
    width: 100vw;
    position: fixed;
    z-index: -1;
    opacity: .75;
    transition: 0.2s linear;
    top: 0;

    @keyframes fadein {
      0%   { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 1));  }
      10%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 1));  }
      20%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 1));  }
      30%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 1));  }
      40%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 1));  }
      50%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 1));  }
      60%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 1));  }
      70%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 1));  }
      80%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 1));  }
      90%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.98), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 1));  }
      100% { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00));  }
    }

    @keyframes fadeout {
      100%   { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 1));  }
      90%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 1));  }
      80%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 1));  }
      70%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 1));  }
      60%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 1));  }
      50%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 1));  }
      40%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 1));  }
      30%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 1));  }
      20%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 1));  }
      10%  { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.98), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 1));  }
      0% { @include linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00));  }
    }
  }


  @media screen and (max-width: 1024px) {
    #gradient {
      // Reverse gradient direction for bottom navbar
      @include linear-gradient($appGradientReverse);

      @keyframes fadein {
        0%   { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 1));  }
        10%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 1));  }
        20%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 1));  }
        30%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 1));  }
        40%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 1));  }
        50%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 1));  }
        60%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 1));  }
        70%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 1));  }
        80%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 1));  }
        90%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.98), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 1));  }
        100% { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00));  }
      }

      @keyframes fadeout {
        100%   { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 1));  }
        90%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 1));  }
        80%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 1));  }
        70%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 1));  }
        60%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 1));  }
        50%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 1));  }
        40%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 1));  }
        30%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.94), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 1));  }
        20%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 1));  }
        10%  { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.98), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.96), rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 1));  }
        0% { @include linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00), rgba(0, 0, 0, 1.00));  }
      }
    }
  }
}

