@import '../../../assets/scss';

.button {
  border-radius: 25px;
  background-color: $colorButtonBackgroundActive;
  color: $colorButtonTextActive;
  border: none;
  outline: none;
  text-align: center;
  padding: 10px 15px;
  cursor: pointer;

  &.outline {
    background-color: rgba(0,0,0,0);
    border: 1px solid $colorButtonBackgroundActive;
  }

  &.color {
    color: $colorAccent;
  }

  &.button-square-corners {
    border-radius: 0;
  }

  &[disabled] {
    cursor: auto;
  }

  &.not-allowed {
    cursor: not-allowed;
  }

  @include mobile {
    &.outline {
      border-width: 2px;
    }
  }
}
