@import '../../assets/scss';

main.hotel-info {
  .hotel-info-default {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    gap: 30pt;

    a {
      text-decoration: none;
    }

    .featured-features {
      $iconHeight: 3vh;
      $iconMinHeight: 25pt;
      display: flex;
      gap: 20pt;

      & > * {
        height: $iconHeight;
        min-height: $iconMinHeight;
        width: auto;
      }

      & > img {
        // brightness(0) saturate(100%) to make initially black
        // rest to change to white
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(284deg) brightness(102%) contrast(100%);
      }

      .view-more-button {
        svg {
          height: 100%;
          width: auto;

          & * {
            stroke: $colorAccent;
          }
        }
      }
    }

    .social-buttons {
      display: flex;
      justify-content: center;
      gap: 20pt;
      width: 200pt;

      img {
        height: 4vh;
        min-height: 30pt;
        width: auto;
      }
    }
  }

  @include desktop {
    padding-top: 5vh;
  }

  @include mobile {
    .hotel-info-default {
      .logo {
        height: $heightMobileLogoDefault;
      }

      .social-buttons {
        width: 30%;
      }
    }

    @include mobile768 {
      .hotel-info-default {
        .logo {
          height: $heightMobileLogo768;
        }

        .social-buttons {
          width: 40%;
        }
      }
    }

    @include mobile540 {
      .hotel-info-default {
        .social-buttons {
          width: 50%;
        }
      }
    }

    @include mobile480 {
      .hotel-info-default {
        .social-buttons {
          width: 65%;
        }
      }
    }
  }
 }
