@import '../../assets/scss';

main.map {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  #map-nav {
    order: 2;
    position: relative;
  }

  .poi-carousel {
    order: 1;
    position: relative;
  }

  //.map-container {
  //  @include mobile {
  //    z-index: -1;
  //  }
  //}
}

.poi-carousel {
  pointer-events: none;
  position: relative;
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  // margin-top: 200px;
  height: 100%;
  @include overflowScroll();

  @include mobile {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  .carousel-navigation-container {
    pointer-events: auto;
    width: 100%;
    max-width: 100%;
    height: 100px;
    background-color: white;
    z-index: 3;

    .carousel-navigation {
      max-width: 75%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      background-color: white;


      a {
        text-decoration: inherit;
        color: inherit;
        cursor: pointer;
      }

      .hidden {
        visibility: hidden;
      }
    }
  }

  .carousel-container {
    // height: 75%;
  }

  .carousel {
    overflow: scroll;
  }
}


.poi-carousel-slide {
  overflow: scroll;
  display: flex;
  flex-direction: column;

  @include overflowScroll();
  position: relative;


  .empty {
    height: 100%;
    min-height: 200px;
  }

  .content {
    pointer-events: auto;
    background: white;
    z-index: 3;

    .image {
      margin-top: 20px;

      img {
        max-height: 200px;
        width: auto;
        margin: 0 auto;
      }
    }

    .slider {
      margin-top: 20px;
      max-height: 200px;
      max-width: 100%;
      height: 100%;
    }

    .description {
      color: black;
      background: white;
      max-width: 75%;
      margin: auto;
      line-height: 1.4em;

      p {

      }

      hr {
        width: 20%;
      }

      .short-description {
        margin-top: 20px;
      }

      .feature-description {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .featured-features {
          $iconHeight: 3vh;
          $iconMinHeight: 25pt;
          display: flex;
          gap: 20pt;

          & > * {
            height: $iconHeight;
            min-height: $iconMinHeight;
            width: auto;
          }

          & > img {
            filter: brightness(0) saturate(100%);
          }

          .view-more-button {
            svg {
              height: 100%;
              width: auto;

              & * {
                stroke: $colorAccent;
              }
            }
          }
        }
      }
    }

    .placeholder {
      padding-top: 1.4em;
    }

    .external-link-description {
      padding: 0.7em 0;
    }

    .contact-button-container {
      padding: 0 0 2em 0;
    }
  }

  .poi-description {
    height: fit-content;
    background-color: white;
    width: 100%;
    padding: 20pt 0;
  }

  .img-container {
    width: 100%;
    background-color: white;
    height: fit-content;
  }
}

