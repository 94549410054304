@mixin desktop1400 {
    @media (min-width: 1400px) {
        @content;
    }
}

@mixin desktop1200 {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin mobile768 {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin mobile540 {
    @media (max-width: 540px) {
        @content;
    }
}

@mixin mobile480 {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin mobile360 {
    @media (max-width: 360px) {
        @content;
    }
}

// Then use as such:
// @include desktop {
//    ... rules only for desktop ...
// }