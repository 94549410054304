@import '../../../assets/scss';

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: $modalPadding;

  max-width: $modalMaxWidth;
  max-height: $modalMaxHeight;

  //@include overflowScroll();

  background-color: $colorModalBackground;

  color: $colorModalText;

  .header {
    color: $colorModalHeadline;
    //background-color: red;
    font-size: $fontSizeHeader4;
    font-weight: $fontWeightBold;
    margin-bottom: calc(#{$modalPadding} / 2);
  }

  .message {
    margin-bottom: calc(#{$modalPadding} / 2);
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: calc(#{$modalPadding} / 2);
    gap: 10px;

    button {
      height: 10%;
      background: none;
      color: $colorAccent;
      border: none;
      //padding: 0;
      font: inherit;
      cursor: pointer;
      outline: none;
      font-weight: $fontWeightBold;

      &:disabled {
        color: $colorGreyLight;
        cursor: default;
      }

      &:active {
        opacity: 50%;
      }
      &:focus {
        background-color: $colorWhite;
        color: $colorAccent;
        outline: 1px solid $colorAccent;
      }
    }
  }

  @include mobile {
    width: fit-content;
  }
}
