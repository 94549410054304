@import "../../../assets/scss";

.document-list {
  ul {
    @include ulRemoveDefaults();

    li {
      //height: $listHeight;
      //line-height: $listLineHeight;
      @include list();
      padding: $listPaddingDesktop;
      background-color: transparent;
      display: flex;
      text-overflow: ellipsis;
      overflow: hidden;
      border-bottom: $listSeparatorBorder;
      margin-right: 20px;

      a {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1vw;
        cursor: pointer;
        //font-size: $fontSizeBody;
        margin-right: 0;
        //line-height: $lineHeightExtraExtraLarge;
        color: $colorText;
        width: 100%;
        transition: color 0.3s linear;

        span {
          text-align: left;
          width: calc(100% - 60px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        img {
          transform: rotateZ(-90deg);
        }
      }

      &.active {
        a {
          color: $colorAccent;
        }
      }
    }
  }

  &.document-list-mobile {
    @include mobile {
      ul {
        @include ulRemoveDefaults();
        //padding-inline-start: 1vw;
        //padding-inline-end: 1vw;

        li {
          margin-right: 0;
          padding: 0;

          a {
            //font-size: $fontSizeBodyLarge;

            img {
              display: none;
            }
          }

          &.active {
            a {
              color: $colorText;
            }
          }
        }
      }

      @include mobile768 {
        ul {
          li {
            a {
              //font-weight: $fontWeightNormal;
            }
          }
        }
      }
    }
  }
}