@import '../../assets/scss';

.daily-posts {
  &.daily-posts-mobile {
    @include mobile {
      .post-slide {
        .post-slide-content {
          top: 0;
          height: 100%;
        }
      }
    }
  }
}