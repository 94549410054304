@import '../../assets/scss';

.features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100%;
  gap: 30pt;
  color: $colorBlack;

  .feature-group {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $paddingXContainerMobileDefault;
    gap: 10pt;
    width: 100%;

    .feature-group-name {
      font-size: $fontSizeSubHeading;
      font-weight: $fontWeightLight;
    }

    .feature-group-items {
      font-weight: $fontWeightNormal;
      display: grid;
      grid-gap: 10pt;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: max-content;
      width: 100%;

      .feature-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10pt;
        text-align: left;

        img {
          $iconHeight: 3vh;
          $iconMinHeight: 25pt;
          height: $iconHeight;
          min-height: $iconMinHeight;
        }
      }
    }
  }

  @include desktop {
    width: 100%;
    @include overflowScroll();
  }

  @include mobile {
    @include mobile768 {
      .feature-group {
        .feature-group-items {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    @include mobile360 {
      .feature-group {
        .feature-group-items {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}


@include mobile {
  .mobile-view-generic-light {
    main {
      background-color: $colorWhite;
    }
  }
}
