@import '../../../assets/scss';

.swiper {
  padding: 0 1vh;
  width: 65%;
  height: 100%;
  @include mobile {
    padding: 0;
    width: 100%;
  }
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  opacity: 0.4;
  overflow: hidden;
  transition: .7s;

  &.swiper-slide-active {
    opacity: 1;
  }

  img {
    width: auto;
    max-width: 90%;
    height: 200px;
    object-fit: contain;

    @include mobile {
      max-width: 82%;
    }
  }
}

.scroll-button {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
}

.left {
  left: -1vh;
}

.right {
  right: -1vh;
}