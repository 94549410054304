@import "../../assets/scss";

.mapboxgl-popup {
  //background: silver;
  color: $colorBlack;
  z-index: 2;

  & * {
    font-size: $fontSizeBody;
  }


  .mapboxgl-popup-content {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 5px;

    .caption {
      word-break: break-word;
    }

    button {
      @include removeButtonStyling();
    }
  }


}
