@import "../../assets/scss";

#map-nav {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: $heightMapNav;
  background: rgba(255,255,255,0.5);
  @include overflowScroll();
  padding: 10px 0 0 0;
  z-index: 3;

  .scrollable-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: fit-content;
  }
}
