@import '../../assets/scss';

main.summary {
  @include tileGrid(summary);

  &.summary-gallery {
    @include tileGrid(gallery);
  }

  &.summary-mobile {
    font-weight: $fontWeightNormal;
    font-size: $fontSizeHeader5;
  }

  .summary-tile {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    cursor: pointer;

    .summary-tile-gradient {
      @include linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1), transparent);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: $paddingTileGrid;
      display: flex;
      justify-content: space-between;

      .summary-tile-name {
        text-align: left;
        padding-right: 15px;
      }

      .summary-tile-day {
        white-space: nowrap;
      }
    }
  }
}