@import '../../assets/scss';

.features-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 60vw;
  min-height: 40vh;
  max-width: $mainDesktopWidthDefault;
  max-height: 90vh;
  background-color: $colorModalBackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
  border-radius: 5px;

  a.close {
    align-self: flex-end;
    padding: 10px 10px 0 0;

    img {
      height: $modalCloseBtnHeight;
      width:  $modalCloseBtnWidth;
    }
  }

  @include desktop1200 {
    max-width: $mainDesktopWidth1200;
  }
}
