@import '../../assets/scss';

.daily-weather {
  &.daily-weather-desktop {
    height: 100%;

    & > * {
      //width: calc(98vw - 80px - 10px); // Keep within arrows
      //max-width: calc(1400px - 80px - 10px); // check this + responsive
      width: $mainDesktopWidthDefault;
      max-width: $mainDesktopMaxWidthDefault;
      margin: auto;
    }

    ul.weather-items-container {
      height: 100%;

      li.weather-item-desktop {
        .top {
          justify-content: center;

          .name {
            font-size: $fontSizeHeader2;
          }

          .main-weather-info {
            display: none;
          }
        }
      }
    }

    .toggle-units-container {
      text-align: right;

      .MuiToggleButtonGroup-root {
        margin-right: 0;
      }
    }



    @include desktop1200 {
      & > * {
        //max-width: calc(1225px - 80px - 10px);
        width: $mainDesktopWidth1200;
      }
    }
  }

  &.daily-weather-mobile {
    @include mobile {
      height: 100%;
      padding: 0 calc(#{$paddingXContainerMobileDefault} + #{$paddingXMainElementMobileDefault});

      @include mobile768 {
        padding: 0 calc(#{$paddingXContainerMobile768} + #{$paddingXMainElementMobileDefault});
      }

      ul.weather-items-container {
        height: 100%;
      }
    }
  }
}
