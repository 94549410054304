@import '../../../assets/scss';

.map-navigation-button {
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  justify-content: flex-start;
  //overflow: hidden;
  width: 75pt;

  .img-container {
    position: relative;
    height: fit-content;
    width: fit-content;

    .MuiSvgIcon-root {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $colorWhite;
      background-color: $colorAccent;
      border-radius: 50%;
      height: 35%;
      width: auto;
      display: none;
    }
  }

  .label {
    height: 36px;
    display: block;
    font-size: 14px;
    line-height: 18px;
  }

  &.active {
    .img-container {
      .MuiSvgIcon-root {
        display: block;
      }
    }
  }

  &.map-navigation-button-maps {
    color: black;

    .img-container {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  &.map-navigation-button-pois {
    color: white;
    min-width: 100px;
    .img-container {
      img {
        display: block;
        width: 100%;
        max-width: 40px;
        height: auto;
        filter: invert(1);
        margin-bottom: 10px;
      }
    }
  }


  @include mobile {
    width: calc(100vw / 6);

    @include mobile768 {
      width: 25vw;
    }
  }
}