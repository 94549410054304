@import '../../assets/scss';

.track-list-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

ul.track-list {
  @include ulRemoveDefaults();
  position: fixed;
  box-sizing: border-box;
  //top: 0;
  //left: 0;
  bottom: calc(#{$heightMapNav} + #{$paddingBottomMapNav});
  background-color: $colorWhite;
  //padding: 17px;
  margin-bottom: 5px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
  //max-width: 60vw;
  z-index: 101;
  overflow: auto;
  height: fit-content;
  max-height: calc(100vh - (#{$heightMapNav} * 1.5));

  li {
    padding: 15px;
    border-bottom: 1px solid $colorListDivider;
    text-align: left;
    cursor: pointer;
    display: flex;
    gap: 15px;
    color: $colorBlack;

    &:last-of-type {
      border-bottom: none;
    }

    &.toggle-all {
      border-top: 5px solid $colorListDivider;
    }

    span.tick {
      color: $colorWhite;

      &.active {
        color: $colorBlack;
      }
    }
  }

  @include mobile {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 80vw;
  }
}
