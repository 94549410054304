@import '../../assets/scss';

main.inspirations {
  position: relative;
  display: grid;
  grid-template-rows: 0fr 92fr 8fr 0fr;

  .carousel-container {
    .carousel {
      // to create gap between slide and insp bar. Can't do grid row gap
      // as it makes gaps for all elements in the grid, but we only want a gap
      // between these two
      height: calc(100% - #{$containerYMargin});
    }
  }

  .contact-button-container {
    position: absolute;

    // note 8% corresponds to 8fr in the parent grid
    bottom: calc(8% + #{$containerYMargin});
    left: 50%;
    transform: translateX(-50%);
    width: $mainDesktopWidthDefault;
    max-width: $mainDesktopMaxWidthDefault;
    margin: 0 auto;
    height: $heightInspirationContactDesktop;
    padding-top: $heightInspirationContactDesktop;
    pointer-events: none;


    .contact-button {
      font-size: $fontSizeButton;
      pointer-events: all;

      &:active {
        opacity: 50%;
      }
      &:focus {
        background-color: $colorWhite;
        color: $colorAccent;
      }
    }
  }

  @include desktop {
    @include desktop1200 {
      .contact-button-container {
        width: $mainDesktopWidth1200;
      }
    }
  }

  &.inspirations-mobile {
    @include mobile {
      display: block;

      .contact-button-container {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background: rgba(0,0,0,0.8);
        @include linear-gradient(180deg,transparent,rgba(0,0,0,1) 50%);
        height: $heightInspirationContactMobile;
        padding-top: $heightInspirationContactMobile;
        pointer-events: none;

        .contact-button {
          border-color: $colorWhite;
          pointer-events: all;
        }
      }
    }
  }
}

.contact-us-modal {
  .header {
    margin-right: max(150px);
    margin-bottom: 20px;
  }
}
