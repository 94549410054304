@import '../../assets/scss';

.sign-in-buttons {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
    width: min-content;
}

.apple-sign-in-button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: #fff!important;
    color: #000000!important;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border: 1px solid #d1d3d5;
    letter-spacing: 0.25px;
    justify-content: center;

    &:hover {
        background-color: #e0eaff;
        opacity: 0.9;
    }
}

.apple-sign-in-button-icon {
    width: 30px;
    height: 30px;
    margin-left: -22px;
}

.apple-sign-in-button-text {
    display: inline-block;
}

.custom-sign-in-button {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-top: -7px;

    &:hover {
        opacity: 0.9;
    }
}

.custom-btn {
    width: 100%;
    height: 100%;
}

