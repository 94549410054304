@import '../../assets/scss';

.cookie-consent-container {
  background-color: $colorGreyLight !important;
  color: $colorWhite !important;

  a {
    color: $colorOrange;
  }

  .cookie-consent-button-wrapper {
    .cookie-consent-button {
      background-color: $colorOrange !important;
      color: $colorWhite !important;
    }
  }
}