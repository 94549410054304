@import 'assets/scss/index.scss';

html {
  font-size: 16px;

  body {
    font-family: $fontFamBody;
    font-weight: $fontWeightNormal;
    color: $colorText;
    background-color: $colorDefaultBackground;
    //transition-duration: 10s;
    //transition: all .3s linear;
    left: 0;
    right: 0;
    z-index: -1;
    background-position: center center !important;
    display: block;
    background-size: cover !important;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    margin: 0;

    a {
      color: $colorAccent;
    }
  }

  @include desktop {
    @include desktop1400 {
      font-size: 18px;
    }

    @include desktop1200 {
      font-size: 17px;
    }
  }

  @include mobile {
    body.blur {
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }

    font-size: 18px;

    @include mobile768 {
      font-size: 17px;
    }

    @include mobile360 {
      font-size: 15px;
    }
  }

}
