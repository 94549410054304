@import '../../../assets/scss';

.MuiBox-root {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid $colorGreyLight;
  border-bottom: 1px solid $colorGreyLight;

  &.mobile-nav {
    @include mobile {
      position: absolute;
      bottom: 40px;
      left: 0;
      width: 100%;
      border: none;

      .MuiTabs-root {
        width: 100%;

        .MuiTabs-scrollButtons {
          position: absolute;
          display: block;
          padding: 10px 10px 20px;
          margin: 0;
          top: 0;
          z-index: 100;

          &:first-child {
            left: 0;
          }

          &:last-child {
            right: 0;
          }

          img.chevron-left,
          img.chevron-right {
            height: 2.5vh;
            width: auto;
          }
        }

        .MuiTabs-scroller {
          display: flex;
          justify-content: center;
          width: 100vw;

          .MuiTabs-flexContainer {
            //display: grid;
            //grid-template-columns: repeat(100, 16.6vw);
            display: flex;
            gap: 0 !important;
            //justify-items: center;
            // justify-content: center;
            height: auto;
            max-width: 100%;
            width: fit-content;

            a {
              font-size: $fontSizeMobileNav;
              line-height: $lineHeightSmall;
              max-height: 30px;
              font-weight: $fontWeightNormal;
              padding: 8px;
              opacity: 100%;
              justify-content: flex-start;
              word-break: break-word;
              width: calc(100vw / 6);
              margin: 0;
            }
          }

          .MuiTabs-indicator {
            display: none;
          }
        }
      }

      @include mobile768 {
        .MuiTabs-root {
          .MuiTabs-scroller {
            .MuiTabs-flexContainer {
              //grid-template-columns: repeat(100, 25vw);
              a {
                width: 25vw;
              }
            }
          }
        }
      }
    }
    .show-arrows .MuiTabs-scrollButtons:first-child {
      display: block !important;
    }
  }

  .MuiTabs-scroller {
    .MuiTabs-flexContainer {
      align-items: center;
      height: 7vh;

      a {
        color: $colorText;
        opacity: 70%;
        text-transform: none;
        font-family: $fontFamBody;
        font-weight: $fontWeightNormal;
        font-size: $fontSizeBody;
        transition: 0.2s linear;
        margin: 0 1vw;

        &:hover {
          opacity: 100%;
        }
      }

      a.Mui-selected {
        color: $colorText;
        opacity: 100%;
      }

      .nav-item-icon {
        width: 23px;
        height: 23px;
      }
    }

    .MuiTabs-indicator {
      background-color: $colorAccent;
      height: 5px;
      border-radius: 5px 5px 0 0;
    }
  }

  &.indicator-hidden {
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        display: none;
      }
    }
  }
}
