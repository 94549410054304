@import '../../assets/scss/index.scss';

$bottomNavHeight: 195px;

.container-poi-list {
    main {
        overflow: hidden;
        ul.poi-list {
            margin: 0;
            padding: 0 10px;
            @include desktop {
                padding: 20px 0;
                // max-width: 600px;
                margin: auto;
            }
            @include mobile {
                padding: 0;
            }
            height: calc(100% - #{$bottomNavHeight});
            width: 100%;
            @include overflowScroll();
            li {
                cursor: pointer;
                display: grid;
                grid-template-columns: auto 60px;
                column-gap: 40px;
                text-align: left;
                line-height: 1.5em;
                list-style-type: none;
                padding: 15px 0;
                margin: 0;
                border-bottom: $listSeparatorBorder;
                font-weight: normal;
                .name {
                    font-weight: bold;
                    grid-column: 1;
                    font-size: $fontSizeBodyLarge;
                    margin-bottom: 10px;
                }
                .description {
                    grid-column: 1;
                    max-height: 3em;
                    overflow: hidden;
                    font-size: $fontSizeBody;
                    line-height: 1.5em;
                }
                .icon {
                    grid-column: 2;
                    grid-row: 1;
                    grid-row-end: 3;
                    text-align: center;
                    img {
                        width: 100%;
                        max-width: 40px;
                        height: auto;
                        filter: invert(1);
                    }
                    .distance {
                        font-size: smaller;
                    }
                }
            }
        }
        .bottom {
            height: $bottomNavHeight;
            .toggle-poi-buttons {
                //float: right;
                display: flex;
                justify-content: flex-end;
                width: 100%;
            }
            ul.poi-categories {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                min-width: 100%;
                @include overflowScroll();
                justify-content: space-between;
                //justify-content: center;
                gap: 10px;
                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    min-width: 100px;
                    margin: auto;
                    img {
                        display: block;
                        width: 100%;
                        max-width: 40px;
                        height: auto;
                        filter: invert(1);
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

