@import '../../assets/scss';

$paddingX: 5%;
$gradientFadeHeight: 25vh;

.inspiration-slide {
  width: 100vw;
  display: flex;
  justify-content: center;

  .content {
    width: $mainDesktopWidthDefault;
    max-width: $mainDesktopMaxWidthDefault;
    position: relative;
    background-size: cover;
    background-position: 50%;

    .content-inner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: $heightInspirationContactDesktop;
      right: 0;
      width: 100%;

      @include overflowScroll();

      .logo {
        display: none;
      }

      .text-content {
        position: absolute;
        box-sizing: border-box;
        top: calc(100% - #{$gradientFadeHeight} - #{$lineHeightSectionHeading} - #{$heightInspirationContactDesktop});
        padding: $gradientFadeHeight $paddingX $heightInspirationContactDesktop $paddingX;
        width: 100%;
        @include linear-gradient(180deg,transparent, rgba(0,0,0,0.7) $gradientFadeHeight);

        .title {
          font-size: $fontSizeSectionHeading;
          line-height: $lineHeightSectionHeading;
          pointer-events: none;
        }

        .description {
          font-size: $fontSizeParagraph;
          line-height: $lineHeightParagraph;
        }
      }
    }

    .contact-button-gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      height: calc(#{$heightInspirationContactDesktop}*2);
      width: 100%;
      @include linear-gradient(180deg,transparent,rgba(0,0,0,1) 50%);

    }
  }

  @include desktop1200 {
    .content {
      width: $mainDesktopWidth1200;
    }
  }

  @include mobile {
    // Main header & insp navbar are both $mobileHeaderHeightDefault
    $contentInnerTop: calc(#{$mobileHeaderHeightDefault} * 2);

    .content {
      width: 100%;

      .content-inner {
        top: $contentInnerTop;
        $bottom: $heightInspirationContactMobile;

        .logo {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: $heightMobileLogoDefault;
          display: block;
        }

        .text-content {
          padding: $gradientFadeHeight $paddingX $heightInspirationContactMobile $paddingX;
          top: calc(100% - #{$gradientFadeHeight} - #{$lineHeightParagraph} - #{$heightInspirationContactMobile});

          .title {
            display: none;
          }
        }
      }

      .contact-button-gradient {
        display: none;
      }
    }

    @include mobile768 {
      $paddingX: 10%;

      .content {
        .content-inner {
          .logo {
           height: $heightMobileLogo768;
          }
        }
      }
    }
  }
}