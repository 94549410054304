@import '../../../assets/scss';

.container {
  background-position: center center !important;
  background-size: cover !important;

  //&.non-itinerary-route {
  //  .header {
  //    .MuiSvgIcon-root {
  //      color: $colorLoginNavigation;
  //    }
  //  }
  //}

  main {
    @include overflowScroll();
  }
}

.container-desktop {
  height: 100%;
  position: fixed;
  top:0; left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    order: 0;
  }

  .main-nav.MuiBox-root {
    order: 1;
  }

  .main-nav-hidden {
    visibility: hidden;
  }

  main {
    width: $mainDesktopWidthDefault;
    max-width: $mainDesktopMaxWidthDefault;
    order: 2;
    margin: $containerYMargin auto;
    height: 100%;

    &.carousel-mode {
      width: 100vw;
      max-width: 100vw;
      top: 0;
      left: 0;

      &.full-screen-desktop-carousel {
        position: fixed;
        margin: 0;
      }
    }
  }

  footer {
    order: 3
  }

  @include desktop1200 {
    main {
      width: $mainDesktopWidth1200;
    }
  }


  // NOTE: mobile home screen uses container desktop in order to show the
  // navbar, header, footer
  @include mobile {
    main {
      order: 1;
    }

    .main-nav.mobile-nav.MuiBox-root {
      order: 2;

      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          //grid-template-columns: 0 repeat(100, 16.6vw); // 0 to hide first

          a:first-of-type {
            // Hide first. Mui doesn't like display none so must do it this way
            visibility: hidden;
            height: 0;
            width: 0;
            position: absolute; // This will hide it
          }
        }
      }
    }
  }
}

.container-mobile {
  @include mobile {
    //height: 100vh;
    height: 100%;
    width: $mainMobileWidthDefault;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: $mobileHeaderHeightDefault calc(100vh - #{$mobileHeaderHeightDefault});
    padding: 0 $paddingXContainerMobileDefault;
    position: fixed;
    top:0; left: 0; right: 0; bottom: 0;

    main {
      padding: calc( #{$paddingXMainElementMobileDefault} * 2 ) $paddingXMainElementMobileDefault 0;

      &.carousel-mode {
        position: fixed;
        height: 100%;
        width: 100vw;
        max-width: 100vw;
        top: 0;
        left: 0;
        padding: 0;
      }
    }

    &.mobile-view-generic {
      padding: 0;
      width: 100%;
      background-color: $colorDefaultBackground;
    }

    @include mobile768 {
      width: $mainMobileWidth768;
      padding: 0 calc((100vw - #{$mainMobileWidth768}) / 2);
    }
  }
}

