@import '../../assets/scss';

.daily-map {
  height: 100%;
  position: relative;

  .map-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include overflowScroll();
  }



  @include desktop {
    //width: calc(98vw - 80px - 20px); // Keep within arrows
    //max-width: calc(1400px - 80px - 20px); // check this + responsive
    width: $mainDesktopWidthDefault;
    max-width: $mainDesktopMaxWidthDefault;
    margin: auto;

    @include desktop1200 {
      width: $mainDesktopWidth1200;
    }
  }

  @include mobile {
    //padding: 0 1vw;
    padding: 0;
  }
}
