@import '../../assets/scss';

.inspiration-bar-container {
  position: relative;
  //height: 10%;
  border-top: 1px solid $colorGreyLight;
  border-bottom: 1px solid $colorGreyLight;
  width: 100vw;
  display: flex;
  align-items: center;
  @include overflowScroll();

  ul.inspiration-bar {
    position: absolute;
    @include ulRemoveDefaults();
    display: flex;
    height: 100%;
    transition: margin-left 0.5s ease-in-out;

    li.inspiration-bar-item {
      cursor: pointer;
      height: 100%;
      //margin: 0 5px;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      white-space: pre;

      .title {
        font-size: $fontSizeNavbar;
        line-height: $lineHeightNavbar;
        max-height: calc(#{$lineHeightNavbar} * 1);
        overflow: hidden;
        max-width: 98vw;
        text-overflow: ellipsis;
      }


      .indicator {
        align-self: flex-end;
        height: 5px;
        background-color: $colorAccent;
        width: 100%;
        border-radius: 5px 5px 0 0;
        position: absolute;
        bottom: 0;
        display: none;
      }

      &.active {
        .indicator {
          display: block;
        }
      }
    }
  }

  @include mobile {
    position: absolute;

    top: $mobileHeaderHeightDefault;
    left: 0;
    border: none;
    height: $mobileHeaderHeightDefault;

    ul.inspiration-bar {
      li.inspiration-bar-item {
        .title {
          font-size: calc(#{$fontSizeNavbar} * 1.3)
        }

        &.active {
          .title {
            font-weight: $fontWeightBold;
          }

         .indicator {
            display: none;
          }
        }
      }
    }
  }
}
