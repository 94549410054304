@import '../../../assets/scss';

.user-select-modal {
  .friendly-name-set {
    max-height: $modalMaxHeight;
    max-width: $modalMaxWidth;
    font-weight: $fontWeightNormal;

    .message {
      margin-bottom: calc(#{$modalPadding}/2);
    }

    input {
      padding: 5px 2px;
      outline: none;
      border: none;
      width: 100%;

      &::placeholder {
        color: $colorGreyLight;
      }

      &:focus {
        border-bottom: 2px solid $colorAccent;
        caret-color: $colorAccent;
      }
    }
  }

  .user-select {
    max-height: $modalMaxHeight;

    form {
      max-height: calc(#{$modalMaxHeight} * 0.8);
      @include overflowScroll();

      .user-option {
        padding: calc(#{$modalPadding}/3) 0;
        font-weight: $fontWeightNormal;
        display: flex;
        justify-content: flex-start;
        gap: calc(#{$modalPadding} / 2);

        $radioBtnWidth: 14px;

        input {
          //margin: 0 calc(#{$modalPadding}/2) 0 0;
          margin: 0;
          -webkit-appearance: none;
          //appearance: auto;
          width: $radioBtnWidth;
          height: $radioBtnWidth;
          border-radius: 50%;
          outline: none;
          border: 2px solid $colorAccent !important;

          &:before {
            content: '';
            display: block;
            width: 70%;
            height: 70%;
            margin: 20% auto;
            border-radius: 50%;
          }

          &:checked {
            &:before {
              background-color: $colorAccent !important;
            }
          }
        }

        label {
          max-width: calc(#{$modalMaxWidth} - #{$radioBtnWidth} - (#{$modalPadding} / 2));
          text-overflow: ellipsis;
        }
      }
    }
  }

  @include mobile {
    .friendly-name-set {
      width: 90vw;
      max-width: $modalMaxWidth;
    }
  }

}