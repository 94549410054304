@import '../../../../assets/scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  text-align: center;
}

.headline {
  font-size: $fontSizeSectionHeading;
  line-height: $lineHeightSectionHeading;
  margin: 10vh 0;
}

.short-info {
  line-height: $lineHeightParagraph;
  font-size: $fontSizeParagraph;
  margin-bottom: 5vh;
}

.substrate {
  color: $colorAboutText;
  background: $colorAboutBackground;
  padding: 5vh;
  border-radius: 0 0 5vh 5vh;
}
