@import '../../assets/scss';

.container-map {
    .header, .main-nav {
        background: rgba(0,0,0,0.75);
        z-index: 2;
    }
    main.map {
        margin: 0;
        max-width: 100%;
        width: 100%;
        position: relative;
        // background: red;

        .map-container {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;
        }
    }
    footer {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    @include mobile {
        .header {
            background-color: rgba(0,0,0,0);
            //color: black;
            //
            //& * {
            //    color: black;
            //}
        }

        .header-white {
            background-color: white;
        }

        main.map {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0;
        }
    }

}


.marker, .mapboxgl-popup {
    position: absolute;
    top: 0px;
    left: 0px;

    svg {
        pointer-events: none;
    }
}

.marker {
    cursor: pointer;

    &.marker-poi {
        // background-image: url('mapbox-icon.png');
        background-size: cover;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}
