@import './fonts';

$desktopHeaderHeightDefault: 15vh;
$desktopHeaderHeight1200: 17vh;
$mobileHeaderHeightDefault: 7vh;

$mainDesktopWidthDefault: 85vw;
$mainDesktopMaxWidthDefault: 1400px;
$mainDesktopWidth1200: 70vw;
$mainMobileWidthDefault: 98vw;
$mainMobileWidth768: 96vw;

$chevronSmallHeight: 4vh;
$chevronLargeHeightDefault: 80px;
$chevronLargeHeightMobile: 68px;
$chevronLargeHeightMobile768: 55px;
$chevronLargeHeightMobile540: 50px;

$postsWidthDefault: 600px;
$postsWidthMobile: 100%;
$postTilePaddingDefault: 1.5rem;
$postTilePaddingMobile: 1rem;
$postTileMarginDefault: 1rem 0;

$modalPadding: 20px;
$modalCloseBtnHeight: 0.8rem;
$modalCloseBtnWidth: 0.8rem;
$modalMaxHeight: calc(70vh - (#{$modalPadding} * 2));
$modalMaxWidth: calc(90vw - (#{$modalPadding} * 2));

$containerYMargin: 1vh;

$scrollBtnWidth: 60px;

$heightInspirationContactDesktop: 6vh;
$heightInspirationContactMobile: 9vh;
$marginYDesktopLogo: 1vh;
$heightDesktopLogoDefault: calc(#{$desktopHeaderHeightDefault} - (#{$marginYDesktopLogo} * 2));
$heightDesktopLogo1200: calc(#{$desktopHeaderHeight1200} - (#{$marginYDesktopLogo} * 2));
$heightMobileLogoDefault: 15vh;
$heightMobileLogo768: 10vh;

$paddingTileGrid: 10px;

$paddingXContainerMobileDefault: calc((100vw - #{$mainMobileWidthDefault}) / 2);
$paddingXContainerMobile768: calc((100vw - #{$mainMobileWidth768}) / 2);
$paddingYContainerMobilePreview: $containerYMargin;
$paddingXMainElementMobileDefault: 10px;

//$listPaddingDesktop: 0 0 0 1rem;
$listPaddingDesktop: 0;
$listLineHeight: $lineHeightExtraExtraLarge;
$listHeight: calc(#{$listLineHeight} + 1px);
$listLineHeightMobile: $lineHeightExtraLarge;
$listHeightMobile: calc(#{$listLineHeightMobile} + 1px);

$heightMapNav: 100px;
$paddingBottomMapNav: 20px;

