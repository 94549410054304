@import "../../../assets/scss";

.MuiButton-root.hamburger-btn {
  border-radius: 4px 4px 0 0;
  transition: none;
  background-color: rgba(0, 0, 0, 0);
  //color: $colorWhite;
  border: none;
  cursor: pointer;

  &.btn-color-white {
    color: $colorWhite;
  }

  &.btn-color-login-nav {
    color: $colorLoginNavigation;
  }

  &.btn-color-accent {
    color: $colorAccent;
  }

  @include mobile {
    .MuiSvgIcon-root {
      font-size: 40px;
    }
  }
}

.MuiModal-root.hamburger-menu {
    display: block;
    font-family: inherit;

    .MuiPaper-root {
      background-color: $colorMenuBackground;

      .boxes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      .MuiBox-root {
        // It is already display: flex, but in a row
        flex-direction: column;
        border: none;

        ul.MuiList-root {
          .MuiButtonBase-root {
            width: 100%;
            &.itinerary-item {
              background-color: $colorMenuItineraryBackground;
              margin-bottom: 1px;

              &:last-of-type {
                border-bottom: none;
              }

              .MuiListItemText-root {
                .MuiTypography-root{
                  color: $colorMenuItineraryText;
                }
              }
            }

            &:active {
              background-color: $colorMenuDivider;
            }
            &:focus {
              opacity: 70%;
            }

            .MuiListItemText-root {
              .MuiTypography-root {
                font-family: $fontFamBody;
                color: $colorMenuText;
                font-weight: $fontWeightNormal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        hr.MuiDivider-root {
          color: $colorMenuDivider;
          border-color: $colorMenuDivider;
        }
      }
    }
}
