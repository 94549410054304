@import '../../../assets/scss';

.chevron-small {
  height: $chevronSmallHeight;
}

.chevron-large {
  height: $chevronLargeHeightDefault;

  @include mobile {
    height: $chevronLargeHeightMobile;

    @include mobile768 {
      height: $chevronLargeHeightMobile768;
    }

    @include mobile540 {
      height: $chevronLargeHeightMobile540;
    }
  }
}