@import "../../assets/scss/index";

.MuiInputLabel-root {
  color: $colorAccent !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 2px $colorAccent solid !important;
}

.MuiSelect-select {
  min-width: 150px !important;
}
