@import '../../assets/scss';

.container-home {
  header {
    z-index: 1;
  }

  .main-nav.MuiBox-root {
    z-index: 1;
  }
}

main.home {
  .full-screen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //z-index: -1; // So the full screen doesn't go over header, nav, etc.

    .home-centered-stay {
      .passcode-form {
        position: absolute;
        bottom: 25vh;

        input {
          margin-bottom: 10pt;
        }

        .button {
          background-color: $colorButtonBackgroundActive;
          color: $colorButtonTextActive;

          &[disabled] {
            background-color: $colorButtonBackgroundInactive;
            color: $colorButtonTextInactive;
          }
        }
      }
    }
  }
}

main.home,
main.brief {
  .home-centered {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: $fontSizeHeader2;
      line-height: $lineHeightExtraExtraLarge;
      //background-color: green;
    }

    .date-info {
      font-weight: $fontWeightNormal;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 10px;
      margin-top: 1vh;
    }

    .day-number {
      font-size: $fontSizeHeader0;
    }

    .countdown {
      //background-color: blue;
      display: flex;
      justify-content: center;

      .separator {
        font-size: $fontSizeHeader1;
        align-self: start;
        // mTop = (height of .value - height of .separator) / 2
        margin-top: calc((#{$fontSizeHeaderMax} - #{$fontSizeHeader1}) / 2);
      }

      &>* {
        //background-color: red;
        margin: 0 0.5vw;

        .value {
          font-size: $fontSizeHeaderMax;
        }

        .definition {
          font-size: $fontSizeBody;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .home-centered {
      font-weight: $fontWeightNormal;

      .day-number {
        font-size: $fontSizeHeader1;
      }

      &>* {
        margin: 0 1vw;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .home-centered {
      .title {
        line-height: $lineHeightExtraLarge;
      }

      .date-info {
        border: none;
      }

      .day-number {
        font-size: $fontSizeHeader2;
        line-height: $lineHeightExtraLarge;
      }

      .countdown {
        .separator {
          font-size: $fontSizeHeader3;
          // mTop = (height of .value - height of .separator) / 2
          margin-top: calc((#{$fontSizeHeader1} - #{$fontSizeHeader3}) / 2);
        }

        &>* {
          .value {
            font-size: $fontSizeHeader1;
          }

          .definition {
            font-size: $fontSizeBodySmall;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home-centered {
      .title {
        font-size: $fontSizeHeader3;
        line-height: $lineHeightLarge;
      }

      .date-info {
        border: none;
      }

      .countdown {
        .separator {
          // mTop = (height of .value - height of .separator) / 2
          margin-top: calc((#{$fontSizeHeader2} - #{$fontSizeHeader3}) / 2);
        }

        &>* {
          .value {
            font-size: $fontSizeHeader2;
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .home-centered {
      .day-number {
        font-size: $fontSizeHeader3;
      }

      .countdown {
        .separator {
          font-weight: $fontWeightLight;
        }

        &>* {
          .value {
            font-weight: $fontWeightLight;
          }
        }
      }
    }
  }
}

main.brief {
  .home-centered {
    width: 100vw;
    position: absolute;
    top: calc(50% - (#{$mobileHeaderHeightDefault}/2));
    transform: translateY(-50%);
    z-index: 0;
  }
}