@import '../../assets/scss';

$padding: 1rem;

.options-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $colorModalBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: $padding;
  gap: 1.5rem;
  //border-radius: 5px;

  color: $colorModalText;

  @include desktop {
    min-width: 300px;
  }

  @include mobile {
    width: 70vw;
  }

  .MuiCircularProgress-root {
    position: absolute;
    align-self: center;
  }

  img.close {
    height: $modalCloseBtnHeight;
    width: $modalCloseBtnWidth;
    top: 10px;
    left: 10px;
    cursor: pointer;
    align-self: end;
    margin-bottom: -1.5rem;
  }

  .header {
    margin-top: 1rem;
    color: $colorModalHeadline;
    font-size: $fontSizeModalHeader;
    font-weight: $fontWeightModalHeader;
  }

  ul {
    @include ulRemoveDefaults();
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    li {
      cursor: pointer;
      &.not-allowed {
        cursor: not-allowed;
      }
    }
  }

  .wrapper-move {
    width: 100%;


    .content-move {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 1.5rem;
    }

    .button-move {
      display: flex;
      justify-content: flex-end;
    }

    button {
      height: 10%;
      background: none;
      color: $colorAccent;
      border: none;
      font: inherit;
      cursor: pointer;
      outline: none;
      font-weight: $fontWeightBold;

      &:disabled {
        color: $colorGreyLight;
        cursor: default;
      }

      &:active {
        opacity: 50%;
      }

      &:focus {
        background-color: $colorWhite;
        color: $colorAccent;
        outline: 1px solid $colorAccent;
      }
    }

  }
}
