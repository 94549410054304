@import "../../assets/scss";

.container-login {
  box-sizing: border-box;
  background-color: $colorLoginBackground;

  &.border-tblr {
    border: 10px solid $colorLoginBorder;
  }

  &.border-tb {
    border-top: 10px solid $colorLoginBorder;
    border-bottom: 10px solid $colorLoginBorder;
  }

  &.border-lr {
    border-left: 10px solid $colorLoginBorder;
    border-right: 10px solid $colorLoginBorder;
  }

  .desktop-header {
    z-index: 1;
  }

  .login-gradient {
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: -2;

    &.hidden {
      display: none;
    }
  }
}