.MuiToggleButtonGroup-root {
    margin: 20px;
    background: rgba(white, 0.5);
    button {
        width: 50%;
        padding: 0 10px;

        &.Mui-selected, &:hover {
            background-color: rgba(white, 0.7) !important;
        }
    }
}
