@import '../../assets/scss';

.slide-content-summary {
  @include linear-gradient(180deg, transparent, rgba(0,0,0,.8));

  @include mobile {
    &.slide-content-storyboard {
      @include linear-gradient(180deg, transparent, rgba(0,0,0,.4));
    }
  }
}
.daily-summary {
  text-align: left;
  // @include linear-gradient(180deg, transparent, rgba(0,0,0,.8));
  margin-top: -16px;
  padding-bottom: 120px;

  @include mobile {
    text-align: center;
  }

  .mobile-top {
    margin-bottom: 20px;
  }

  &.daily-summary-gallery {
    text-align: center;
    padding-bottom: 0;
    width: 100%;
  }

  .date-info {
    .day {
      font-size: $fontSizeHeader3;
      line-height: $lineHeightExtraLarge;
    }

    .date {
      line-height: $lineHeight;
    }
  }

  & > * {
    //width: calc(98vw - 80px - 10px); // Keep within arrows
    //max-width: calc(1400px - 80px - 10px); // check this + responsive
    width: $mainDesktopWidthDefault;
    max-width: $mainDesktopMaxWidthDefault;
    margin: auto;

  }

  .headline {
    font-size: $fontSizeSectionHeading;
    line-height: $lineHeightSectionHeading;
    padding: 12px 0;
    margin: 20px auto;
  }

  .short-info {
    line-height: $lineHeightParagraph;
    font-size: $fontSizeParagraph;

    a {
      color: $colorAccent;
    }
  }

  @include desktop1200 {
    & > * {
      //max-width: calc(1225px - 80px - 10px);
      width: $mainDesktopWidth1200;
    }
  }

  @include mobile {
    &.show-gradient {
      @include linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 5%, rgba(0, 0, 0, .7) 70%);
    }
  }
}
